/* global $, KTUtil, KTOffcanvas */
'use strict'

$(document).on('vue-ready', function () {
  let KTQuickPanel = (function () {
    let panel = KTUtil.get('kt_quick_panel')
    let notificationPanel = KTUtil.get('kt_quick_panel_tab_notifications')
    let logsPanel = KTUtil.get('kt_quick_panel_tab_logs')
    let settingsPanel = KTUtil.get('kt_quick_panel_tab_settings')

    let getContentHeight = function () {
      let height
      let nav = KTUtil.find(panel, '.kt-quick-panel__nav')

      height = parseInt(KTUtil.getViewPort().height) - parseInt(KTUtil.actualHeight(nav)) - (2 * parseInt(KTUtil.css(nav, 'padding-top'))) - 10

      return height
    }

    let initOffcanvas = function () {
      new KTOffcanvas(panel, {
        overlay: true,
        baseClass: 'kt-quick-panel',
        closeBy: 'kt_quick_panel_close_btn',
        toggleBy: 'kt_quick_panel_toggler_btn'
      })
    }

    let initNotifications = function () {
      KTUtil.scrollInit(notificationPanel, {
        disableForMobile: true,
        resetHeightOnDestroy: true,
        handleWindowResize: true,
        height: function () {
          return getContentHeight()
        }
      })
    }

    let initLogs = function () {
      KTUtil.scrollInit(logsPanel, {
        disableForMobile: true,
        resetHeightOnDestroy: true,
        handleWindowResize: true,
        height: function () {
          return getContentHeight()
        }
      })
    }

    let initSettings = function () {
      KTUtil.scrollInit(settingsPanel, {
        disableForMobile: true,
        resetHeightOnDestroy: true,
        handleWindowResize: true,
        height: function () {
          return getContentHeight()
        }
      })
    }

    let updatePerfectScrollbars = function () {
      $(panel).find('a[data-toggle="tab"]').on('shown.bs.tab', function () {
        KTUtil.scrollUpdate(notificationPanel)
        KTUtil.scrollUpdate(logsPanel)
        KTUtil.scrollUpdate(settingsPanel)
      })
    }

    return {
      init: function () {
        initOffcanvas()
        initNotifications()
        initLogs()
        initSettings()
        updatePerfectScrollbars()
      }
    }
  }())

  let KTQuickSearch = function () {
    let target
    let form
    let input
    let closeIcon
    let resultWrapper
    let resultDropdown
    let resultDropdownToggle
    let inputGroup
    let query = ''

    let hasResult = false
    let timeout = false
    let isProcessing = false
    let requestTimeout = 200 // ajax request fire timeout in milliseconds
    let spinnerClass = 'kt-spinner kt-spinner--input kt-spinner--sm kt-spinner--brand kt-spinner--right'
    let resultClass = 'kt-quick-search--has-result'
    let minLength = 2

    let showProgress = function () {
      isProcessing = true
      KTUtil.addClass(inputGroup, spinnerClass)

      if (closeIcon) {
        KTUtil.hide(closeIcon)
      }
    }

    let hideProgress = function () {
      isProcessing = false
      KTUtil.removeClass(inputGroup, spinnerClass)

      if (closeIcon) {
        if (input.value.length < minLength) {
          KTUtil.hide(closeIcon)
        } else {
          KTUtil.show(closeIcon, 'flex')
        }
      }
    }

    let showDropdown = function () {
      if (resultDropdownToggle && !KTUtil.hasClass(resultDropdown, 'show')) {
        $(resultDropdownToggle).dropdown('toggle')
        $(resultDropdownToggle).dropdown('update')
      }
    }

    let hideDropdown = function () {
      if (resultDropdownToggle && KTUtil.hasClass(resultDropdown, 'show')) {
        $(resultDropdownToggle).dropdown('toggle')
      }
    }

    let processSearch = function () {
      if (hasResult && query === input.value) {
        hideProgress()
        KTUtil.addClass(target, resultClass)
        showDropdown()
        KTUtil.scrollUpdate(resultWrapper)

        return
      }

      query = input.value

      KTUtil.removeClass(target, resultClass)
      showProgress()

      setTimeout(function () {
        $.ajax({
          url: 'https://keenthemes.com/madwire/themes/themes/madwire/dist/preview/inc/api/quick_search.php',
          data: {
            query: query
          },
          dataType: 'html',
          success: function (res) {
            hasResult = true
            hideProgress()
            KTUtil.addClass(target, resultClass)
            KTUtil.setHTML(resultWrapper, res)
            showDropdown()
            KTUtil.scrollUpdate(resultWrapper)
          },
          error: function () {
            hasResult = false
            hideProgress()
            KTUtil.addClass(target, resultClass)
            KTUtil.setHTML(resultWrapper, '<span class="kt-quick-search__message">Connection error. Pleae try again later.</div>')
            showDropdown()
            KTUtil.scrollUpdate(resultWrapper)
          }
        })
      }, 1000)
    }

    let handleCancel = function () {
      input.value = ''
      query = ''
      hasResult = false
      KTUtil.hide(closeIcon)
      KTUtil.removeClass(target, resultClass)
      hideDropdown()
    }

    let handleSearch = function () {
      if (input.value.length < minLength) {
        hideProgress()
        hideDropdown()

        return
      }

      if (isProcessing === true) {
        return
      }

      if (timeout) {
        clearTimeout(timeout)
      }

      timeout = setTimeout(function () {
        processSearch()
      }, requestTimeout)
    }

    return {
      init: function (element) {
        // Init
        target = element
        form = KTUtil.find(target, '.kt-quick-search__form')
        input = KTUtil.find(target, '.kt-quick-search__input')
        closeIcon = KTUtil.find(target, '.kt-quick-search__close')
        resultWrapper = KTUtil.find(target, '.kt-quick-search__wrapper')
        resultDropdown = KTUtil.find(target, '.dropdown-menu')
        resultDropdownToggle = KTUtil.find(target, '[data-toggle="dropdown"]')
        inputGroup = KTUtil.find(target, '.input-group')

        // Attach input keyup handler
        KTUtil.addEvent(input, 'keyup', handleSearch)
        KTUtil.addEvent(input, 'focus', handleSearch)

        // Prevent enter click
        form.onkeypress = function (e) {
          let key = e.charCode || e.keyCode || 0
          if (key === 13) {
            e.preventDefault()
          }
        }

        KTUtil.addEvent(closeIcon, 'click', handleCancel)

        // Auto-focus on the form input on dropdown form open
        let toggle = KTUtil.getByID('kt_quick_search_toggle')
        if (toggle) {
          $(toggle).on('shown.bs.dropdown', function () {
            input.focus()
          })
        }
      }
    }
  }

  let KTQuickSearchMobile = KTQuickSearch

  // ---------------------------------------------------------------------------

  KTQuickPanel.init()

  if (KTUtil.get('kt_quick_search_default')) {
    KTQuickSearch().init(KTUtil.get('kt_quick_search_default'))
  }

  if (KTUtil.get('kt_quick_search_inline')) {
    KTQuickSearchMobile().init(KTUtil.get('kt_quick_search_inline'))
  }
});
